/* ----------------------------------------------------------------------------
randomly shift and scale child elements
---------------------------------------------------------------------------- */

export default class JitterBug extends HTMLElement {
  static get observedAttributes() {
    return ['scale', 'jitter', 'rotation'];
  }

  get scale() {
    return this.hasAttribute('scale') ? this.getAttribute('scale') : '0';
  }

  get jitter() {
    return this.hasAttribute('jitter') ? this.getAttribute('jitter') : '0';
  }

  get rotation() {
    return this.hasAttribute('rotation') ? this.getAttribute('rotation') : '0';
  }

  static random(min, max) {
    const minimum = Math.ceil(min);
    const maximum = Math.floor(max);
    return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
  }

  makeMess() {
    this.targetElements.forEach((element) => {
      const rS = JitterBug.random(this.scale / 1.5, this.scale * 1.5);
      const rX = JitterBug.random(this.jitter * -1, this.jitter);
      const rY = JitterBug.random((this.jitter / 2) * -1, this.jitter / 2);
      const rR = JitterBug.random(this.rotation * -1, this.rotation);
      const el = element;

      if (this.jitter > 0) {
        el.style.translate = `${rX}rem ${rY}rem`;
      }

      if (this.scale > 0) {
        el.style.scale = `${rS}%`;
      }

      if (this.rotation > 0) {
        el.style.rotate = `${rR}deg`;
      }
    });
  }

  connectedCallback() {
    this.ariaHidden = 'true';
    this.targetElements = this.querySelectorAll(':scope > *');
    this.makeMess();
  }
}

window.customElements.define('jitter-bug', JitterBug);
