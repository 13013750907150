import JitterBug from './_elements/jitter-bug.js';
import MakeConfetti from './_elements/make-confetti.js';
// import './_utilities/scroll-timeline.js';

document.addEventListener('DOMContentLoaded', () => {

  /* ---------------------------------------------------------------------------
  how it works
  --------------------------------------------------------------------------- */

  let mm = gsap.matchMedia();

  mm.add('(prefers-reduced-motion: no-preference)', () => {

    // Activity cards
    const answerTimeline = window.gsap.timeline({
      scrollTrigger: {
        trigger: '.activity-card',
        scrub: true,
        start: 'top center',
        end: '50%',
      },
    });

    answerTimeline.to('.faux-activity', {
      duration: 0.01,
      opacity: 0,
      y: '90%',
    }).to('.faux-activity', {
      opacity: 1,
      y: '0%',
      duration: 2,
      stagger: 0.4,
      ease: 'power1.inOut',
    })

    // Updates cards
    const updatesTimeline = window.gsap.timeline({
      scrollTrigger: {
        trigger: '.updates-card',
        scrub: true,
        start: 'top center',
        end: '30%',
      },
    });

    updatesTimeline.to('.faux-update', {
      duration: 0.01,
      y: '90%',
    }).to('.faux-update', { 
      y: '0%',
      duration: 2,
      stagger: 0.4,
      ease: 'power1.out',
    })

    // Digest cards
    const digestTimeline = window.gsap.timeline({
      scrollTrigger: {
        trigger: '.digest-card',
        scrub: 0,
        start: 'top bottom',
        end: '20%',
      },
    });

    digestTimeline.to('.faux-browser .faux-update', {
      duration: 0,
      y: '-300%',
    }).to('.faux-browser', {
      duration: 0,
      y: '100%',
    }).to('.faux-dot', {
      duration: 0,
      opacity: 0,
      y: -450,
    }).to('.faux-browser .faux-update', { 
      y: '0%',
      scale: 1,
      duration: 2,
      ease: 'power1.inOut',
    }).to('.faux-dot', {
      duration: 1,
      stagger: {
          each: 0.03,
          grid: [1,2],
          axis: 'y',
          from: 'edges',
          ease: 'linear',
      },
      y: 200,
      opacity: 1,
      ease: 'linear',
    }, '<').to('.faux-browser', { 
      y: '0%',
      duration: 1,
      ease: 'power1.inOut',
    }, '<');

  });

  /* ---------------------------------------------------------------------------
  modern work is broken
  --------------------------------------------------------------------------- */

  // problem: productivity
  window.gsap.to('.grind', {
    scrollTrigger: {
      trigger: '.problem-productivity',
      scrub: true,
      start: '-30%',
      end: '90%',
    },
    duration: 1,
    y: '0%',
  });

  window.gsap.to('.shredder-project', {
    scrollTrigger: {
      trigger: '.problem-reason',
      scrub: true,
      start: '0%',
      end: '70%',
    },
    duration: 1,
    y: '100%',
  });

  // solution: intro
  const solutionIntroOverlay = window.gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-intro',
      start: '0%',
      end: '65% center',
      scrub: true,
    },
  });

  solutionIntroOverlay.from('.steady-hero-overlay', { opacity: 0 })
    .to('.steady-hero-overlay', { opacity: 1, duration: 2 })
    .to('.steady-hero-overlay', { opacity: 1, duration: 40 })
    .to('.steady-hero-overlay', { opacity: 0, duration: 1 });

    window.gsap.to('.steady-hero', {
    scrollTrigger: {
      trigger: '.solution-intro',
      scrub: true,
      start: '25% center',
      end: '30%',
    },
    duration: 1,
    opacity: 1,
    scale: 1,
  });

  // solution: productivity
  const spPie = window.gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '20% bottom',
      end: '60% center',
      scrub: true,
    },
  });

  spPie.from('.pie-filling.--quo', { '--percentage': '0%', rotation: 0 })
    .to('.pie-filling.--quo', { '--percentage': '80%', rotation: 90, duration: 1 });

  const spPieAlt = window.gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '20% bottom',
      end: '60% center',
      scrub: true,
    },
  });

  spPieAlt.from('.pie-filling.--alternate', { '--percentage': '0%', rotation: 0 })
    .to('.pie-filling.--alternate', { '--percentage': '25%', rotation: 90, duration: 1 });

    window.gsap.to('.pie-label', {
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '65% bottom',
      end: '66% center',
      scrub: true,
    },
    duration: 1,
    opacity: 1,
    translateX: 0,
  });

  // solution: energy
  window.gsap.from('.bar .fill', {
    scrollTrigger: {
      trigger: '.solution-energy',
      start: '20% bottom',
      end: '50% center',
      scrub: true,
    },
    duration: 1,
    height: '0%',
  });
});
